import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'
import { useEffect, useState } from 'react'
import type { JobsResponse } from '../utils/getVacancyTypes'
import parseNextQuery from '../utils/parseNextQuery'
import NavigationCardsSection from './NavigationCardsSection'
import FilterBar from '../components/FilterBar'

const Section = styled.section(
  css`
    display: flex;
    width: 100%;
    justify-content: center;
  `,
)

const Container = styled.div(
  css`
    display: flex;
    flex-flow: column;
    width: 100%;
  `,
)

interface Props {
  filterBy: string
  sortBy: string
  dateAsc: string
  dateDesc: string
  placeholderImage: ImageInfo
  filterAll: string
  pathname: string
  vacancies: JobsResponse
}

const JobListingSection = ({
  filterBy,
  sortBy,
  dateAsc,
  dateDesc,
  placeholderImage,
  filterAll,
  pathname,
  vacancies,
}: Props) => {
  const { query, replace } = useRouter()
  const replaceQuery = (newQuery: ParsedUrlQueryInput) => {
    replace(
      {
        pathname,
        query: {
          ...query,
          ...newQuery,
        },
      },
      undefined,
      {
        scroll: false,
      },
    )
  }

  const jobTypes = vacancies.facets?.functionArea.map((jobType) => ({
    label: jobType,
    value: jobType,
  }))
  const dateSortOptions = [
    { label: dateDesc, value: dateDesc },
    { label: dateAsc, value: dateAsc },
  ]

  const actualJobType = parseNextQuery(query.jobType)
  const actualDateSort = parseNextQuery(query.dateSort)

  const [optimisticJobType, setSelectedjobType] = useState(actualJobType)
  const [optimisticDateSort, setSelectedDateSort] = useState(actualDateSort)

  useEffect(() => {
    setSelectedjobType(parseNextQuery(query.jobType))
  }, [query.jobType])
  useEffect(() => {
    setSelectedDateSort(parseNextQuery(query.dateSort))
  }, [query.dateSort])

  const applyJobType = (newJobType: string[]) => {
    setSelectedjobType(newJobType)
    replaceQuery({ jobType: newJobType, page: 1 })
  }
  const applyDateSort = (newDateSort: string[]) => {
    setSelectedDateSort(newDateSort)
    replaceQuery({ dateSort: newDateSort, page: 1 })
  }

  return (
    <Section>
      <Container>
        <FilterBar
          filterData={[
            {
              label: filterBy,
              placeholder:
                optimisticJobType.length !== 0
                  ? optimisticJobType[0]
                  : 'Job Type',
              onSelect: applyJobType,
              active: optimisticJobType,
              options: [{ label: filterAll, value: '' }, ...(jobTypes ?? [])],
            },
          ]}
          sort={{
            label: sortBy,
            placeholder:
              optimisticDateSort.length !== 0 ? optimisticDateSort[0] : 'Date',
            onSelect: applyDateSort,
            active: optimisticDateSort,
            options: dateSortOptions,
          }}
        />
        {vacancies.items.length > 0 && (
          <NavigationCardsSection
            layout="list"
            cards={vacancies.items.map(
              ({ id, functionName, link, functionArea, image }) => {
                const formattedImage: ImageInfo = {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  src: image!,
                  // This size is enforced in the CMS for the placeholder image, but cannot be set for the vacancy images
                  width: 560,
                  height: 370,
                }

                return {
                  __typename: 'linkCard',
                  key: id,
                  image:
                    formattedImage.src !== undefined &&
                    formattedImage.src !== ''
                      ? formattedImage
                      : placeholderImage,
                  title: functionName,
                  subTitle: functionArea,
                  link: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    href: link!,
                  },
                }
              },
            )}
          />
        )}
      </Container>
    </Section>
  )
}

export default JobListingSection
