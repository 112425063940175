import { JobListingSectionFragment } from '../graphql/jobListingSectionFragment.generated'
import JobListingSection from '../sections/JobListingSection'
import { usePageContext } from '../utils/PageContext'
import convertDatoImage from '../utils/convertDatoImage'
import { formatJobUrl } from '../utils/formatJobUrl'
import getVacanciesOverview from '../utils/getVacanciesOverview'
import type { JobsResponse } from '../utils/getVacancyTypes'

interface Props {
  content: JobListingSectionFragment
  data: {
    vacancies: JobsResponse
  }
}

const JobListingSectionBlock = ({
  content: { filterBy, sortBy, dateAsc, dateDesc, placeholderImage, filterAll },
  data,
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()
  if (!siteConfig.jobsIndex?.slug) {
    return null
  }
  const pathname = `/${siteConfig.jobsIndex?.slug}`

  // Create object from data to add link property
  const vacancies: JobsResponse = {
    items: data.vacancies.items.map((job) => {
      const link = formatJobUrl(job.id, job.functionName, pathname)
      return {
        ...job,
        link,
      }
    }),
    facets: data.vacancies.facets,
  }

  return (
    <JobListingSection
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filterBy={filterBy!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      sortBy={sortBy!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dateAsc={dateAsc!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      dateDesc={dateDesc!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      placeholderImage={convertDatoImage(placeholderImage!)}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filterAll={filterAll!}
      pathname={pathname}
      vacancies={vacancies}
      {...others}
    />
  )
}

export default JobListingSectionBlock

export const getStaticProps = async () => ({
  vacancies: await getVacanciesOverview(),
})
