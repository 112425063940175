import RichText from '../components/RichText'
import { JobFeedSectionFragment } from '../graphql/jobFeedSectionFragment'
import JobFeedSection from '../sections/JobFeedSection'
import { usePageContext } from '../utils/PageContext'
import { formatJobUrl } from '../utils/formatJobUrl'
import getVacanciesOverview from '../utils/getVacanciesOverview'
import type { JobsResponse } from '../utils/getVacancyTypes'
import resolveLink from '../utils/resolveLink'

interface Props {
  content: JobFeedSectionFragment
  data: {
    vacancies: JobsResponse
  }
}

const JobFeedSectionBlock = ({
  content: { title, text, jobs, callToAction },
  data,
  ...others
}: Props) => {
  const { siteConfig } = usePageContext()
  if (!siteConfig.jobsIndex?.slug && jobs.length <= 0) {
    return null
  }

  const pathname = `/${siteConfig.jobsIndex?.slug}`

  const vacancies = {
    items:
      jobs.length > 0
        ? jobs.map(({ id, title }) => {
            return {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              id: id!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              functionName: title!,
            }
          })
        : data.vacancies.items.map((job) => {
            const link = formatJobUrl(job.id, job.functionName, pathname)
            return {
              ...job,
              link,
            }
          }),
    facets: jobs.length > 0 ? undefined : data.vacancies.facets,
  }

  return (
    <JobFeedSection
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      title={title!}
      text={<RichText text={text} />}
      jobs={vacancies}
      callToAction={{
        ...resolveLink(siteConfig, callToAction[0]),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text: callToAction[0].text!,
      }}
      {...others}
    />
  )
}

export default JobFeedSectionBlock

export const getStaticProps = async () => ({
  vacancies: await getVacanciesOverview(4),
})
