import { css } from '@emotion/react'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { makeVar, useReactiveVar } from '../utils/reactiveVar'
import Image from '../components/Image'
import Link from '../components/Link'
import Button, {
  invisibleButtonCss,
  speechButtonCss,
} from '../components/Button'
import ChevronRight from '../icons/ChevronRight.svg'
import HamburgerMenu from '../components/HamburgerMenu'

interface isMobileMenuOpenVarProps {
  mainMenuOpen: boolean
  subMenuOpen: boolean
  subMenuActiveId: string | null
}

export const isMobileMenuOpenVar = makeVar<isMobileMenuOpenVarProps>({
  mainMenuOpen: false,
  subMenuOpen: false,
  subMenuActiveId: null,
})

export const Section = styled.section(({ theme }) => [
  css`
    display: flex;
    flex-flow: row;
    justify-content: center;
    z-index: ${theme.zIndex.relative1};
    background: white;
    box-shadow: 0 1px 0 rgba(87, 118, 69, 0.1),
      0 4px 4px rgba(115, 168, 85, 0.13);
    padding: 0 ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
    }
  `,
])

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x4}px;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: ${theme.spacing.gridMaxWidth}px;
      margin: 0 auto;
    }
  `,
)

const Row = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: flex;
      flex-flow: row;
      gap: ${theme.spacing.x4}px;
    }
  `,
)

const HamburgerMenuButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isMobileMenuOpen',
})<{ isMobileMenuOpen: boolean }>(({ theme, isMobileMenuOpen }) => [
  invisibleButtonCss,
  css`
    width: 28px;
    height: 20px;
    cursor: pointer;
    transition: opacity 400ms ease-in-out;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      display: none;
    }
  `,
  isMobileMenuOpen &&
    css`
      span {
        background-color: transparent;

        ::before {
          transform: rotate(-45deg);
          top: 0;
          background-color: #161670;
        }

        ::after {
          transform: rotate(45deg);
          top: 0;
          background-color: #161670;
        }
      }
    `,
])

const StyledHamburger = styled.span(
  ({ theme }) => css`
    position: relative;
    width: 28px;
    height: 4px;
    display: block;
    background-color: ${theme.colors.primaryBlue};
    transition: 0.5s;
    border-radius: 5px;

    ::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: ${theme.colors.primaryBlue};
      transition: 0.5s;
      border-radius: 5px;
    }

    ::after {
      content: '';
      position: absolute;
      top: 10px;
      display: block;
      width: 100%;
      height: 4px;
      background-color: ${theme.colors.primaryBlue};
      transition: 0.5s;
      border-radius: 5px;
    }
  `,
)

const StyledLogo = styled(Image)(
  ({ theme }) => css`
    width: 120px;
    height: auto;
    padding: ${theme.spacing.x2}px 0;
  `,
)

const Menu = styled.menu(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-flow: row;
      text-align: center;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
    }
  `,
)

const SubMenu = styled.menu(
  ({ theme }) => css`
    display: grid;
    grid-auto-rows: 1fr;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding-left: 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: ${theme.spacing.gridMaxWidth}px;
      margin: 0 auto;
    }
  `,
)

const SubMenuWrapper = styled.div(
  ({ theme }) => css`
    position: absolute;
    flex-flow: column;
    width: 100%;
    list-style: none;
    top: ${theme.spacing.x9}px;
    z-index: ${theme.zIndex.behind};
    margin: 0;
    padding: ${theme.spacing.x3}px;
    overscroll-behavior: none;
    background: ${theme.colors.pageBackgroundColor};
    text-align: left;
    left: 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 1px 0 rgba(87, 118, 69, 0.1),
      0 4px 4px rgba(115, 168, 85, 0.13);
  `,
)

const SubMenuItem = styled.li(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    padding: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      width: 450px;
      padding: ${theme.spacing.x3}px 0;
    }
  `,
)

const SubMenuDescription = styled.div(
  css`
    font-size: 16px;
  `,
)

const SubMenuTitle = styled.span(
  ({ theme }) => css`
    ${theme.text.heading3(theme)};
    display: block;
    margin-bottom: ${theme.spacing.x1}px;
    color: ${theme.colors.primaryBlue};
  `,
)

const SubMenuLink = styled(Link)(
  ({ theme }) => css`
    text-decoration: none;
    color: ${theme.colors.bodyTextColor};

    &:hover,
    &:focus {
      text-decoration: none;

      ${SubMenuTitle} {
        text-decoration: underline;
        text-underline-offset: 8px;
      }
    }
  `,
)

const SubMenuReturnLink = styled(Link)(
  ({ theme }) => css`
    ${theme.links.blue(theme)}
    font-size: 16px;
    padding: ${theme.spacing.x3}px 0;
  `,
)

const SubMenuFooter = styled.div(
  ({ theme }) => css`
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: ${theme.spacing.gridMaxWidth}px;
      margin: 0 auto;
    }
  `,
)

const MenuLink = styled(Link)(
  ({ theme }) => css`
    ${theme.links.blue(theme)}
    font-family: var(--font-hankenGrotesk);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      font-size: 20px;
    }
  `,
)

const MenuItem = styled('li', {
  shouldForwardProp: (prop) => prop !== 'hasSubMenu',
})<{ hasSubMenu: boolean }>(({ theme, hasSubMenu }) => [
  css`
    display: flex;
    flex-flow: column;
    padding: ${theme.spacing.x2}px;
    border-bottom: 1px solid ${theme.colors.neutral1};
    padding: ${theme.spacing.x3}px;

    &:hover,
    &:focus-within {
      ${SubMenuWrapper} {
        transform: translateY(0);
        transition: transform 0.3s ease-in-out;
      }

      ${MenuLink} {
        color: black;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      // Has to be 27 or higher to prevent space between hover and SubMenu
      padding: 27px;
      border-bottom: none;

      :not(:first-of-type) {
        ::after {
          content: '';
          position: absolute;
          width: 1px;
          background: ${theme.colors.neutral1};
          height: ${theme.spacing.x6}px;
          top: 50%;
          transform: translateY(-50%);
          margin-left: -${theme.spacing.x3}px;
        }
      }
    }
  `,

  hasSubMenu &&
    css`
      &:hover,
      &:focus-within {
        ::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background: black;
          opacity: 0.5;
          z-index: ${theme.zIndex.behind};
          pointer-events: none;
          transition: transform 0.3s ease-in-out;
        }
      }
    `,
])

const CallToAction = styled(Link)(({ theme }) => [
  speechButtonCss(theme),
  css`
    @media screen and (max-width: ${theme.breakpoints.desktopLarge}px) {
      display: none;
    }
  `,
])

interface Props {
  logo?: ImageInfo
  links: Array<{
    link: {
      key: string
      href: string
      target?: string
      text: string
    }
    subLinks?: Array<{
      link: {
        key: string
        href: string
        target?: string
        text: string
      }
      brief?: string
    }>
  }>
  callToAction: {
    href: string
    target?: string
    text: string
  }
  goTo: string
}

const NavigationBarSection = ({ logo, links, callToAction, goTo }: Props) => {
  const { mainMenuOpen } = useReactiveVar(isMobileMenuOpenVar)

  const { events, asPath } = useRouter()

  useEffect(() => {
    const closeMenu = () => {
      isMobileMenuOpenVar({
        mainMenuOpen: false,
        subMenuOpen: false,
        subMenuActiveId: null,
      })
    }

    events.on('routeChangeStart', closeMenu)

    return () => {
      events.off('routeChangeStart', closeMenu)
    }
  }, [events])

  // We put a key here to rerender the menu every time we navigate to another page
  return (
    <Section key={asPath}>
      <Container>
        <Row>
          {logo && (
            <Link href="/">
              <StyledLogo
                src={logo}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                alt={logo.alt!}
                width={120}
                height={55}
                aspectRatio="3/2"
              />
            </Link>
          )}
          <HamburgerMenuButton
            onClick={() =>
              isMobileMenuOpenVar({
                mainMenuOpen: !mainMenuOpen,
                subMenuOpen: false,
                subMenuActiveId: null,
              })
            }
            isMobileMenuOpen={mainMenuOpen}
            aria-label="MobileMenuButton"
          >
            <StyledHamburger />
          </HamburgerMenuButton>
          <Menu>
            {links.map(({ link, subLinks }) => {
              if (subLinks && subLinks?.length > 0) {
                return (
                  <MenuItem key={link.key} hasSubMenu>
                    <MenuLink href={link.href} target={link.target}>
                      {link.text}
                    </MenuLink>
                    <SubMenuWrapper>
                      <SubMenu key={link.key}>
                        {subLinks.map(({ link, brief }) => (
                          <SubMenuItem key={link.key}>
                            <SubMenuLink
                              href={link.href}
                              target={link.target}
                              prefetch={false}
                            >
                              <SubMenuTitle>{link.text}</SubMenuTitle>
                              <SubMenuDescription>{brief}</SubMenuDescription>
                            </SubMenuLink>
                          </SubMenuItem>
                        ))}
                      </SubMenu>
                      <SubMenuFooter>
                        <SubMenuReturnLink
                          href={link.href}
                          target={link.target}
                          prefetch={false}
                        >
                          {goTo} {link.text}
                          <ChevronRight />
                        </SubMenuReturnLink>
                      </SubMenuFooter>
                    </SubMenuWrapper>
                  </MenuItem>
                )
              }
              return (
                <MenuItem key={link.key} hasSubMenu={false}>
                  <MenuLink href={link.href} target={link.target}>
                    {link.text}
                  </MenuLink>
                </MenuItem>
              )
            })}
          </Menu>
          <CallToAction href={callToAction.href} target={callToAction.target}>
            {callToAction.text}
            <ChevronRight />
          </CallToAction>
        </Row>
        <HamburgerMenu
          links={links}
          callToAction={{
            href: callToAction.href,
            target: callToAction.target,
            text: callToAction.text,
          }}
          goTo={goTo}
        />
      </Container>
    </Section>
  )
}

export default NavigationBarSection
